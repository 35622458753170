<template>
  <div class="home">
    <!-- <div v-if="$route.name === ' ' || $route.name === 'Ciel Brands' || $route.name === 'BrandProducts' ||$route.path === '/contact-us' || $route.path === '/cart' || $route.path === '/shop'">
      <NavBarBlack />
    </div> -->
    <NavBar :dark="$route.meta.dark" />
    <router-view />
    <FooterCiel />
  </div>
</template>

<script>
import NavBar from './components/NavBar/NavBar.vue'
import FooterCiel from './components/Footer/FooterCiel.vue'

export default {
  components: {
    NavBar,
    FooterCiel,
  },
}
</script>

<style>
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../src/assets/fonts/Inter_18pt-Regular.ttf) format("truetype");
}

:root {
  --header-height: 85px;  
  --header-top-gap: 58px;  
}

.app__negative-headers {
  margin-top: calc(calc(var(--header-height) + var(--header-top-gap)) * -1);
}

.home {
  font-family: 'Inter';
}

</style>
