import { createApp } from "vue";
import VueSplide from "@splidejs/vue-splide";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../node_modules/vue3-marquee-slider/dist/style.css'


import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueSplide);


app.mount("#app");

// Initialize AOS (if necessary)
AOS.init();
