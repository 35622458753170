import FiveTechCollagen from '@/assets/images/ElemisBiotecThefivetechBlack.svg'
import TripleTechElastin from '@/assets/images/ElemisBiotecTripleTechblack.svg'
import AntiRedness from '@/assets/images/ElemisBiotecTheDoubleTechBlack.svg'
import sturmglow from '@/assets/images/sturmglow.svg'
import strumAntiAgeing from '@/assets/images/strumAntiAgeing.svg'
import facePhysicalTraining from '@/assets/images/facePhysicalTraining.svg'
import FireIceFacial from '@/assets/images/iSClinicalFireIceFacialBlack.svg'

export const services = {
  "test": 
    {
      name: 'ELEMIS BIOTEC The Five-Tech Collagen ',
      image: FiveTechCollagen,
      items: [
        {
          label: "KEY BENEFITS",
          description: "This advanced facial treatment is designed to enhance facial structure and sculpt the contour and oval of the face, promoting a visibly firmer, more youthful appearance. It minimizes the appearance of fine lines and wrinkles, leaving the skin smooth and rejuvenated. By restoring moisture and boosting collagen production, this treatment significantly improves skin elasticity and strength, giving a plumper, more radiant complexion. Additionally, it effectively resurfaces the upper layers of the epidermis, revealing a renewed and revitalized skin surface.",
        },
        {
          label: "TECHNOLOGIES USED",
          description: "Innovative technologies amplify the effectiveness of the treatment:",
          keys: ['Microcurrent Lifting to tone facial muscles and improve firmness',
            'Oxygen Infusion to replenish the skin with moisture and nutrients',
            'Ultrasonic Exfoliating Ceramic Spatula to gently exfoliate and renew the skin’s surface',
            'Red & Blue LED therapy for anti-inflammatory and skin-healing effects',
            'Galvanic Current to enhance product penetration and skin hydration'
          ],
        },
        {
          label: "BEST FOR",
          description: "This treatment is suitable for all skin types but is particularly beneficial for:",
          keys: ['Mature skin in need of rejuvenation',
            'Skin lacking elasticity and firmness',
            'Skin with diminished radiance',
            'Dull and tired complexions needing a revitalizing boost ',
          ],
        }
      ]
    },
    "elastine-boost": 
    {
      name: 'ELEMIS BIOTEC The Triple-Tech',
      image: TripleTechElastin,
      items: [
        {
          label: "KEY BENEFITS",
          description: "This facial treatment is designed to sculpt and enhance the facial structure by defining the contour and oval of the face, promoting a firmer, more youthful appearance. It effectively minimizes fine lines and wrinkles, providing a smoother complexion while delivering an overall rejuvenation that restores the skin’s vitality. The treatment maintains optimal moisture levels for a hydrated glow, boosts collagen production to improve elasticity and strength, and resurfaces the upper layers of the epidermis, revealing renewed and revitalized skin.",
        },
        {
          label: "TECHNOLOGIES USED",
          description: "This treatment integrates innovative technologies for enhanced results:",
          keys: ['Microcurrent Lifting to tone and firm facial muscles',
            'Oxygen Infusion to deeply hydrate and replenish the skin',
            'Ultrasonic Exfoliating Ceramic Spatula for gentle exfoliation and skin renewal',
          ],
        },
        {
          label: "BEST FOR",
          description: "This treatment is suitable for all skin types and is ideal for:",
          keys: ['Dull and tired complexions in need of revitalization',
            'Individuals of all ages',
            'Addressing the first signs of aging',
            'Preparing the skin before a special occasion for a radiant glow',
          ],
        }
      ]
    },
    "anti-redness": 
    {
      name: 'ELEMIS BIOTEC The Double-Tech',
      image: AntiRedness,
      items: [
        {
          label: "KEY BENEFITS",
          description: "This calming facial treatment is designed to refine and smooth the epidermis, resulting in a soft and even skin texture. It works to soothe irritation and reduce redness, restoring comfort and balance to the skin. Ideal for sensitive and reactive skin, this treatment helps calm inflammation, leaving the complexion healthier, more resilient, and visibly refreshed. It also enhances the skin's natural barrier, ensuring a long-lasting feeling of comfort and relief.",
        },
        {
          label: "TECHNOLOGIES USED",
          description: "The treatment incorporates modern technologies to boost results:",
          keys: ['Oxygen Infusion to hydrate and calm the skin',
            'Red LED Light to reduce inflammation and promote skin healing',
          ],
        },
        {
          label: "BEST FOR",
          description: "This treatment is particularly beneficial for:",
          keys: ['Dull and tired complexions in need of revitalization',
            'Early signs of aging',
            'Sensitive skin prone to redness and irritation',
            'Rosacea',
            'Reactive skin seeking comfort and balance',
          ],
        }
      ]
    },
    "sturmglow": 
    {
      name: 'Dr. Barbara Sturm- STURMGLOW',
      image: sturmglow,
      items: [
        {
          label: "KEY BENEFITS",
          description: "This luxurious treatment delivers the sought-after Sturm Glow™, leaving the skin radiant, refreshed, and nourished. It strengthens the skin barrier, improving overall skin health and the skin matrix for long-lasting benefits. The treatment restores a natural, healthy-looking glow while deeply nourishing the skin, making it ideal for those looking to rejuvenate their complexion. It is designed to boost radiance and vitality, giving your skin a luminous and revitalized appearance.",
        },
        {
          label: "BEST FOR",
          description: "This treatment is perfect for:",
          keys: ['All skin types',
            'Reviving dull, tired skin',
            'Preparing skin before an event',
            'Monthly skin refresh for maintaining radiance',
            'Special occasions where a radiant, healthy glow is desired',
          ],
        }
      ]
    },
    "face-physical-training": 
    {
      name: 'THERAFACE- The Face Physical Training',
      image: facePhysicalTraining,
      items: [
        {
          label: "KEY BENEFITS",
          description: "This dynamic facial treatment works to reduce inflammation while instantly enhancing the complexion for a fresher, more radiant look. It firms and tones facial muscles, lifting the facial contour for a more sculpted appearance. By improving facial muscle fitness, the treatment supports long-lasting skin health and vitality. Ideal for those seeking an immediate boost, it provides noticeable results, leaving the skin looking rejuvenated and revitalized.",
          keys: ['Reduces inflammation',
            'Instantly enhances complexion',
            'Firms and tones facial muscles',
            'Lifts the facial contour',
            'Improves facial muscle fitness'
          ],
        },
        {
          label: "TECHNOLOGIES USED",
          description: "A range of advanced technologies maximizes the treatment's effectiveness:",
          keys: ['Microcurrent Lifting to tone and firm facial muscles',
            'Cryo / Thermal Ring for soothing and tightening effects',
            'LED Light therapy to reduce inflammation and promote skin healing',
            'Cleansing Ring for a deep, refreshing cleanse',
            'Percussive therapy for improved circulation and muscle relaxation',
          ],
        },
        {
          label: "BEST FOR",
          description: "This treatment is suitable for all skin types and is especially beneficial for:",
          keys: ['Dull complexions needing revitalization',
            'Those seeking a quick fix before an event or special occasion',
          ],
        }
      ]
    },
    "fire-ice-facial": 
    {
      name: 'iS Clinical Fire & Ice Facial',
      image: FireIceFacial,
      items: [
        {
          label: "KEY BENEFITS",
          description: 'The legendary Fire & Ice Facial is a resurfacing and rejuvenating treatment designed to rapidly and safely refresh the skin. Known for its alternating warming and cooling sensations, this professional-grade facial leaves the skin feeling invigorated and renewed. Often called the "Red Carpet Facial," it’s a favorite among celebrities for its ability to deliver immediate, glowing results. The treatment enhances skin texture, reduces the appearance of blemishes, and improves the look of fine lines and wrinkles, all with minimal to no downtime.',
        },
        {
          label: "TECHNOLOGIES USED",
          description: "The treatment focuses on a combination of thermal sensations but does not involve specific devices, relying on the active ingredients in the masques for resurfacing and rejuvenation.",
        },
        {
          label: "BEST FOR",
          description: "This facial is ideal for:",
          keys: ['All skin types',
            'Dull and tired complexions needing a quick refresh',
            'Individuals seeking a pre-event or red carpet glow',
            'Reducing fine lines, blemishes, and uneven skin texture with minimal downtime',
          ],
        }
      ]
    },
    "enzyme-masque": 
    {
      name: 'iS CLINICAL- Foaming Enzyme Masque System',
      image: FireIceFacial,
      items: [
        {
          label: "KEY BENEFITS",
          description: "The Foaming Enzyme Masque System is a powerful yet soothing treatment that combines the exfoliating properties of papaya (papain) and pineapple (bromelain) enzymes with clinical-grade glycolic acid. This luxurious treatment generates a gentle foaming action while bioactive ingredients work to exfoliate and deep-cleanse the skin, resulting in a polished, healthy, and radiant complexion. The masque offers mild but effective exfoliation, leaving the skin smooth, refreshed, and glowing, with reduced pore size and improved texture.",
        },
        {
          label: "TECHNOLOGIES USED",
          description: "The treatment utilizes a combination of natural enzymes and glycolic acid to exfoliate and cleanse. No specific device technologies are used, but the foaming action enhances the treatment’s efficacy.",
        },
        {
          label: "BEST FOR",
          description: "This treatment is ideal for:",
          keys: ['All skin types',
            'Individuals seeking mild, controlled exfoliation',
            'Those looking to reduce pore size and improve skin texture',
            'Anyone desiring a polished, radiant complexion with a paraben-free formulation',
          ],
        }
      ]
    },
    "chemical-peel": 
    {
      name: 'iS CLINICAL- The Chemical Peel',
      image: FireIceFacial,
      items: [
        {
          label: "PRODIGY PEEL SYSTEM",
          description: "The Prodigy Peel System is a powerful, superficial peel designed to address mild acne, visible signs of aging, and pigmentation irregularities. This advanced treatment brightens uneven skin tone, reduces the appearance of fine lines and wrinkles, and leaves the complexion revitalized and radiant. With its smoothing, rejuvenating, and preventative properties, the Prodigy Peel System helps to restore skin clarity and enhance overall skin texture for a youthful, glowing appearance.",
        },
        {
          label: "BENEFITS",
          description: "The treatment consists of applying a series of peels formulated to target specific skin concerns. The peels work to exfoliate the upper layers of the skin, promoting cell renewal and addressing pigmentation, acne, and signs of aging.",
        },
        {
          label: "WHAT TO EXPECT",
          description: "After the treatment, patients may experience the following:",
          keys: [
            'Moderate flaking to mild peeling lasting 3-5 days',
            'Redness that can last anywhere from 3-24 hours',
            'Sensitivity to heat and light for up to 1 week',
            'Tightness or mild itching for up to 1 week',
          ],
        },
      ]
    },
}