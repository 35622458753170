const state = {
  products: [],
  cartItems: [],
}

const getters = {
  getCartItems: (state) => {
    return state.cartItems;
  },
}

const mutations = {
  ADD_TO_CART(state, payload) {
    state.cartItems.push(payload);
  },
  REMOVE_PRODUCT(state, payload) {
    if (payload.idx >= 0 && payload.idx < state.cartItems.length) {
      state.cartItems.splice(payload.idx, 1);
    }
  },
}

const actions = {
  addToCart({ commit, state }, { payload, successCallBack, errorCallBack }) {
    const existingItem = state.cartItems.find(
      (item) => item.id === payload.id
    );
    if (!existingItem) {
      commit("ADD_TO_CART", payload);
      if (successCallBack) successCallBack(); // Call success callback if provided
    } else {
      if (errorCallBack) errorCallBack(); // Call error callback if provided
    }
  },
  removeProduct({ commit }, obj) {
    commit("REMOVE_PRODUCT", obj);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};