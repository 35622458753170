<template>
  <footer :style="{ backgroundColor: footerBackgroundColor }">
    <div class="footer-inner">
      <div class="footer-content container-lg">
        <div class="footerLogo">
          <img
            src="@/assets/images/cielFooter.svg"
            alt="ciel logo"
          >
        </div>
        <div class="footerLinks">
          <div class="socials">
            <p>FOLLOW US</p>
            <a
              href="https://www.instagram.com/cieldebeaute/"
              target="_blank"
            ><span>INSTAGRAM</span></a>
            <a
              href="https://www.facebook.com/cieldebeauteks/"
              target="_blank"
            ><span>FACEBOOK</span></a>
          </div>
          <div class="socials">
            <router-link to="/">
              <span>HOME</span>
            </router-link>
            <router-link to="/brands">
              <span>SHOP</span>
            </router-link>
            <router-link to="/services">
              <span>TREATMENT</span>
            </router-link>
            <router-link to="/contact-us">
              <span>CONTACT</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>


<script>
export default {
  data() {
    return {
      footerBackgroundColor: '#fff',
    };
  },
  watch: {
    $route(to) {
      this.updateFooterBackground(to);
    }
  },
  mounted() {
    this.updateFooterBackground(this.$route);
  },
  methods: {
    updateFooterBackground(route) {
      if (route.meta.footerColor) {
        this.footerBackgroundColor = '#F0EEEB';
      } else if (route.meta.footerRadius) {
        this.footerBackgroundColor = '#D3DEE9';
      } else {
        this.footerBackgroundColor = '#fff';
      }
    }
  }
};
</script>

<style scoped lang="scss">
footer {
  width: 100%;
  
  .footer-inner {
    background: #607D9B;
    border-radius: 34px 34px 0px 0px;
    padding: 80px 0;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerLinks {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }

  .socials {
    display: flex;
    flex-direction: column;
  }

  .socials p {
    margin-bottom: 10px;
    font-size: 22px;
    color: white;
  }

  .socials a {
    text-decoration: none;
    color: white;
    margin-bottom: 10px;
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
    }
    .footerLinks {
      flex-direction: column;
      gap: 40px;
    }

    .footerLogo {
      height: 124px;
      img {
        height: 100%;
      }
    }
  }
}

</style>