<template>
  <div>
    <ContactCiel />
  </div>
</template>

<script>
import ContactCiel from '@/components/Contact/ContactCiel.vue'

export default {
  components: {
    ContactCiel
  }
}
</script>

<style>

</style>