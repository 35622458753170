<template>
  <div>
    <ViewCart />
  </div>
</template>

<script>
import ViewCart from '@/components/Cart/ViewCart.vue'

export default {
  components: {
    ViewCart,
  }
}
</script>