<template>
  <div class="app__negative-headers">
    <div class="carousel-container">
      <Splide
        :options="options"
        aria-label="Services Carousel"
      >
        <SplideSlide>
          <div class="slide-content">
            <img
              src="@/assets/images/treatmentOne.svg"
              alt="Service Image 1"
            >
            <div class="text-overlay">
              Glow is the essence of beauty.
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-content">
            <img
              src="@/assets/images/treatmentTwo.svg"
              alt="Service Image 2"
            >
            <div class="text-overlay">
              Glow is the essence of beauty.
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-content">
            <img
              src="@/assets/images/treatmentThree.svg"
              alt="Service Image 3"
            >
            <div class="text-overlay">
              Glow is the essence of beauty.
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-content">
            <img
              src="@/assets/images/treatmentFour.svg"
              alt="Service Image 4"
            >
            <div class="text-overlay">
              Glow is the essence of beauty.
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-content">
            <img
              src="@/assets/images/treatmentFive.svg"
              alt="Service Image 4"
            >
            <div class="text-overlay">
              Glow is the essence of beauty.
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-content">
            <img
              src="@/assets/images/treatmentSix.svg"
              alt="Service Image 4"
            >
            <div class="text-overlay">
              Glow is the essence of beauty.
            </div>
          </div>
        </SplideSlide>
      </Splide>
    </div>
    <OurServices />
  </div>
</template>

<script>
import '@splidejs/vue-splide/css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import OurServices from '@/components/Services/OurServices.vue';

export default {
  components: {
    Splide,
    SplideSlide,
    OurServices,
  },
  data() {
    return {
      options: {
        type: 'fade',
        pagination: false,
        rewind: true,
        autoplay: true,
        arrows: false,
        interval: 3000,
        perPage: 1,
        perMove: 1,
        breakpoints: {
          1240: { arrows: false, autoplay: true },
          800: { autoplay: true, perPage: 1 },
        },
      },
    };
  },
};
</script>

<style scoped>
.carousel-container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.splide img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.slide-content {
  position: relative;
}
.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 50px;
  font-weight: 500;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}
.splide {
  padding: 0px;
}
</style>
