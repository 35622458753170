<template>
  <div class="details-services container-lg">
    <div
      v-for="service in list"
      :key="service.label"
      class="details-services__timeline"
    >
      <div
        class="details-services__title"
        data-aos="fade"
        data-aos-duration="1500"
      >
        <span>
          {{ service.label }}
        </span>
      </div>
      <div class="details-services__items">
        <p>{{ service.description }}</p>
        <ul>
          <li
            v-for="(benefit, index) in service.keys"
            :key="index"
          >
            {{ benefit }}
          </li>
        </ul>
        <p>{{ service.addons }}</p>
        <p>{{ service.addonDesc }}</p>
      </div>
    </div>
  </div>
</template>

<script>  
export default {
props: {
  list: {
    default: ()=> [],
    type: Array
  }
} 

}
</script>

<style lang="scss">
.details-services {
  padding-top: 80px;
  padding-bottom: 80px;
}
.details-services__timeline {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  min-height: 200px;
  position: relative;
  &:last-child {
    .details-services__title:after {
      display: none;
    }
  }
}

.details-services__title {
  grid-column: span 4;
  padding-left: 40px;
  position: relative;
  span {
    color: #A99175;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    
  }
  &::before {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    background-color: #A99175;
    border-radius: 100px;
    position: absolute;
    left: 0;
  }
  &::after {
    content: '';
    width: 2px;
    height: 100%;
    display: block;
    background-color: #A99175;
    border-radius: 100px;
    position: absolute;
    left: 10px;
    top: 0;
  }
}
.details-services__items {
  grid-column: span 8;
}

@media screen and (max-width: 768px) {
  .details-services__timeline {
    grid-template-columns: repeat(1, 1fr);
  }
  .details-services__title {
    position: static;
  }
  .details-services__items {
    padding-top: 24px;
    padding-left: 40px;
  }
}

</style>