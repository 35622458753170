import { createRouter, createWebHistory } from "vue-router";
import VueRouter from "vue-router";
import HomePage from "@/views/HomePage.vue";
import ShopPage from "@/views/ShopPage.vue"
import ServicesPage from "@/views/ServicesPage.vue"
import ViewProduct from "@/views/ViewProduct.vue";
import ContactPage from "@/views/ContactPage.vue";
import CartPage from "@/views/CartPage.vue";
import CielBrands from "@/views/CielBrands.vue";
import ServiceDetails from "@/views/ServiceDetails.vue";


const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      footerColor: true
    }
  },
  {
    path: "/shop",
    name: "Shop Ciel",
    component: CielBrands,
    meta: {
      dark: true
    }
  },
  {
    path: "/services",
    name: "Services Ciel",
    component: ServicesPage,
  },
  {
    path: "/services/:id",
    name: "Services Ciel Details",
    component: ServiceDetails,
  },
  {
    path: "/brands",
    name: "Ciel Brands",
    component: CielBrands,
    meta: {
      dark: true
    }
  },
  {
    path: "/brands/:brand",
    name: "BrandProducts",
    component: ShopPage,
    meta: {
      dark: true
    }
  },
  {
    path: "/brands/:brand/:id",
    name: "ProductDetails",
    component: ViewProduct,
    meta: {
      dark: true,
      footerRadius: true
    }
  },
  {
    path: "/contact-us",
    name: "Contact Ciel",
    component: ContactPage,
    meta: {
      dark: true
    }
  },
  {
    path: "/cart",
    name: "Cart",
    component: CartPage,
    meta: {
      dark: true
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Scroll to top of the page on every navigation
    return { top: 0 };
  },
});

export default router;
