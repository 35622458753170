<template>
  <div class="creams container-lg">
    <div
      v-if="brand === 'Elemis'"
      class="category-filter"
    >
      <label
        for="categorySelect"
        class="category-label"
      >Filter by Category:</label>
      <select
        id="categorySelect"
        v-model="selectedCategory"
        class="category-dropdown"
        @change="filterProductsByCategory"
      >
        <option value="">
          All Categories
        </option>
        <option
          v-for="category in elmisCategories"
          :key="category"
          :value="category"
        >
          {{ category }}
        </option>
      </select>
    </div>
    <div class="line ">
      <div
        v-for="item in paginatedProducts"
        :key="item.id"
        class="single"
      >
        <router-link :to="`/brands/${item.brand}/${item.id}`">
          <img
            :src="item.images[0]"
            alt="products"
          >
          <p>{{ item.name }}</p>
        </router-link>
      </div>
    </div>
    <div class="pagination">
      <button
        :disabled="currentPage === 1"
        @click="changePage(currentPage - 1)"
      >
        Previous
      </button>

      <span>Page {{ currentPage }} of {{ totalPages }}</span>

      <button
        :disabled="currentPage === totalPages"
        @click="changePage(currentPage + 1)"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import shop from "@/api/shop";

export default {
  data() {
    return {
      products: [],
      filteredProducts: [],
      elmisCategories: [],
      selectedCategory: "",
      perPage: 21,
      currentPage: 1,
    };
  },
  computed: {
    brand() {
      // Get the dynamic brand parameter from the route
      return this.$route.params.brand;
    },
    totalPages() {
      return Math.ceil(this.filteredProducts.length / this.perPage);
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredProducts.slice(start, end);
    },
  },
  watch: {
    // Watch for changes in the brand parameter and refilter products
    brand(newBrand) {
      this.filterProductsByBrand(newBrand);
    },
  },
  created() {
    shop.getProducts((products) => {
      this.products = products;
      this.filterProductsByBrand(this.brand); // Filter products initially based on the brand
    });
  },
  methods: {
    filterProductsByBrand(brand) {
      if (brand === 'Elemis') {
        this.filteredProducts = this.products.filter(product => product.brand === brand);
        this.extractElemisCategories(); // Extract categories for Elemis brand
      } else {
        this.filteredProducts = this.products.filter(product => product.brand === brand);
      }
      this.currentPage = 1; // Reset pagination when filtering
      this.selectedCategory = ""; // Reset selected category
    },
    extractElemisCategories() {
      const elmisProducts = this.products.filter(product => product.brand === 'Elemis');
      this.elmisCategories = [...new Set(elmisProducts.map(product => product.category))];
    },

    filterProductsByCategory() {
      if (this.selectedCategory) {
        this.filteredProducts = this.products.filter(product => 
          product.brand === 'Elemis' && product.category === this.selectedCategory
        );
      } else {
        this.filteredProducts = this.products.filter(product => product.brand === 'Elemis');
      }
      this.currentPage = 1;
    },

    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },
};
</script>

<style scoped>
.creams {
  padding-top: 80px;
  padding-bottom: 80px;
}
.line {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
}
.single {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.line p {
  font-size: 17px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
}
.line img {
  width: 100%;
  height: 300px;
}
.single a {
  text-decoration: none !important;
  color: black;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  align-items: center;
}

button {
  padding: 10px 20px;
  margin: 0 5px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

button:disabled {
  /* opacity: 0.5;
  cursor: not-allowed; */
  display: none
}

/* button:hover:not(:disabled) {
  opacity: 0.8;
} */

.pagination span {
  font-weight: bold;
  margin: 0 10px;
}

.category-filter {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-bottom: 1rem;
  max-width: 300px;
  margin: 20px 0px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.category-label {
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.category-dropdown {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
  font-size: 1rem;
  background-color: #fff;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.category-dropdown:focus {
  border-color: black;
  box-shadow: black;
  outline: none;
}

.category-dropdown:hover {
  border-color: black;
}

.category-dropdown option {
  padding: 10px;
}

.single {
  transition: filter 0.2s ease;
}

.single:hover {
  filter: blur(3px);
}

@media (max-width: 850px) {
  .line {
    grid-template-columns: repeat(2, 1fr);
  }
  .creams {
    padding-top: 20px
  }
}
</style>
