<template>
  <div>
    <div class="logo">
      <img
        src="@/assets/images/CIEL.svg"
        alt="Ciel logo"
      >
    </div>
    <div class="introductory">
      <h2>About Us</h2>
      <div class="vertical-line" />
      <span style="color: #A99175; font-size: 14px; margin: 3% 0%;">{{ norms }}</span>
      <p>{{ AboutCiel }}</p>
      <p>{{ IntroducingCiel }} </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      AboutCiel: 'Welcome to Ciel De Beauté, Kosovo’s first luxury institute where personal care meets skin rejuvenation through nature-inspired treatments and products.',
      IntroducingCiel: 'Ciel de Beauté embodies a philosophy of general well-being, nurturing a balanced approach to mind, body, and skin care. Here, self-care becomes a cherished ritual, encouraging a culture of lasting wellness and beauty.',
      norms: 'GLOW IS THE ESSENCE OF BEAUTY'
    }
  }
}
</script>

<style scoped>
.introductory {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.logo {
  display: flex;
  justify-content: center;
  margin: 5% 0%;
}
.logo img {
  width: 13%;
  height: 100%;
}
.introductory h2 {
  font-size: 37px;
}

.vertical-line {
  width: 1px;
  height: 70px;
  background: #A99175;
  display: flex;
  justify-content: center;
  margin: auto;
}

@media screen and (max-width: 1000px) {
  .introductory {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .logo img {
    width: 60%;
  }
}
</style>