<template>
  <div class="services">
    <div class="title">
      <h3>
        Our Services
      </h3>
      <span style="color: #A99175; font-size: 14px; margin: 3% 0%;">{{ norms }}</span>
    </div>
    <div class="servicesImg">
      <div class="twoServices">
        <router-link to="/services/sturmglow">
          <div class="img-wrapper">
            <img
              src="@/assets/images/barbaraSturmGlow.svg"
              alt="services"
            >
          </div>
        </router-link>
        <router-link to="/services/fire-ice-facial">
          <div class="img-wrapper">
            <img
              src="@/assets/images/iSClinicalFireIceFacial.svg"
              alt="services"
            >
          </div>
        </router-link>
      </div>
      <div>
        <router-link to="/services/test">
          <div class="img-wrapper">
            <img
              src="@/assets/images/ElemisBiotecThefivetech.svg"
              alt="services"
            >
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
data() {
  return {
    norms: 'GLOW IS THE ESSENCE OF BEAUTY'
  }
}
}
</script>

<style scoped>
.services {
  width: 70%;
  margin-top: 10% !important;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
}
.twoServices {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.servicesImg img {
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

.img-wrapper:hover img {
  opacity: 0.5;
}

.img-wrapper {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.img-wrapper:hover {
  background-color: #607D9B8C;
}

@media screen and (min-width: 800px) and (max-width: 1600px) {
  .services {
    width: 80%;
  }
  .servicesImg {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .servicesImg {
    width: 100%;
  }

  .services {
    width: 80%;
  }

  .twoServices {
    display: flex;
    flex-direction: column;
  }
}
</style>