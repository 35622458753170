<template>
  <div class="background">
    <div class="welcome">
      <h1>{{ data?.name }}</h1>
    </div>
  </div>
</template>

<script>
export default {
 props: {
  data: {
    type: Object,
    default: () => {}
   }
 }
}
</script>

<style scoped>   
.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  color: #FFFFFF;
  text-align: center;
}
.welcome h1 {
  font-size: 50px;
}
</style>