<template>
  <div class="contactPage container-lg">
    <form ref="form" @submit.prevent="sendEmail">
      <div class="form-grid">
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input
            id="firstName"
            v-model="form.firstName"
            name="firstName"
            type="text"
            placeholder="John"
            required
          >
        </div>

        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input
            id="lastName"
            v-model="form.lastName"
            name="lastName"
            type="text"
            placeholder="Smith"
            required
          >
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input
            id="email"
            v-model="form.email"
            name="email"
            type="email"
            placeholder="johnsmith@example.com"
            required
          >
        </div>

        <div class="form-group">
          <label for="phone">Phone Number</label>
          <input
            id="phone"
            v-model="form.phoneNumber"
            name="phoneNumber"
            type="tel"
            placeholder="+3834967890"
            required
          >
        </div>

        <div class="form-group full-width">
          <label for="message">Message</label>
          <textarea
            id="message"
            v-model="form.message"
            name="message"
            placeholder="Your message here..."
            rows="4"
            required
          />
        </div>
      </div>
      <div class="form-submit">
        <button type="submit" value="Send">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  data() {
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        message: null,
      }
    }
  },
  methods: {
    sendEmail() {
      const templateParams = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        phoneNumber: this.form.phoneNumber,
        message: this.form.message,
      };

      emailjs.send('service_u4h6cwl', 'template_bhm6228', templateParams, 'lnvL4Yqvd2YQwH7A_')
        .then((result) => {
          console.log('SUCCESS!', result.text);
          alert("Your message has been sent successfully!");
        }, (error) => {
          console.log('FAILED...', error.text);
          alert("There was an error sending your message. Please try again.");
        });
    },
  },
}
</script>

<style scoped lang="scss">
.contactPage {
  display: flex;
  justify-content: center;
  padding-block: 80px;
  form {
    width: 100%;
  }
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  
}
.form-group {
  display: flex;
  flex-direction: column;
}
label {
  margin-bottom: 5px;
  font-weight: bold;
}
.form-group.full-width {
  grid-column: 1 / -1; /* Span across both columns */
}
input, textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}
.form-submit {
  display: flex;
  justify-content: flex-end;
}
.form-submit button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #607D9B;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
}

</style>