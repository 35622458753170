<template>
  <div>
    <ShopProducts />
  </div>
</template>

<script>
import ShopProducts from '@/components/Shop/ShopProducts.vue'

export default {
  components: {
    ShopProducts,
  }
}
</script>

<style scoped>
</style>