<template>
  <div class="products">
    <div class="allItems">
      <div class="title">
        <h4>Our Products</h4>
        <span>{{ norms }}</span>
      </div>
      <div class="product_container">
        <div class="vertical_products">
          <div class="product_short">
            <img src="../../assets/images/faceoilHome.svg" alt="elemis face oil">
          </div>
          <div class="product_long">
            <img src="../../assets/images/elemisProductsHome.svg" alt="elemis cream">
          </div>
        </div>
        <div class="vertical_products">
          <div class="product_long">
            <img src="../../assets/images/k18Home.svg" alt="elemis creams">
          </div>
          <div class="product_short">
            <img src="../../assets/images/isclinicalSerumHome.svg" alt="elemis creams">
          </div>
        </div>
        <div class="vertical_products">
          <div class="product_short">
            <img src="../../assets/images/elemisShampoo.svg" alt="elemis shampoo">
          </div>
          <div class="product_long">
            <img src="../../assets/images/elemisfacetwoHome.svg" alt="elemis products">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      norms: 'GLOW IS THE ESSENCE OF BEAUTY'
    }
  }
}
</script>

<style scoped>
.products {
  background: #F0EEEB;
  margin-top: 5%;
  padding: 5% 0;
}
.allItems {
  max-width: 90%;
  width: 70%;
  margin: auto;
}
.title {
  text-align: right;
  font-size: 1.5em;
}
h4 {
  font-size: 2rem;
}
.title span {
  color: #A99175;
  font-size: 1rem;
}
.product_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
}
.vertical_products {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 30%;
}
.product_short img,
.product_long img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .allItems {
    max-width: 80% !important;
  }
}
@media screen and (max-width: 1200px) {
  .allItems {
    max-width: 90%;
  }
  .product_container {
    flex-direction: column;
    gap: 20px;
  }
  .vertical_products {
    width: 100% !important;
    max-width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .title {
    text-align: left;
  }
  .product_container {
    gap: 10px;
  }

  .vertical_products {
    width: 100% !important;
    max-width: 100%;
  }
}
</style>
