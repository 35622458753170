<template>
  <div class="app__negative-headers">
    <div
      :style="{ backgroundImage: `url(${service.image})` }"
      class="background"
    >
      <ServiceDetailsImage :data="service" />
    </div>
    <DetailsServices :list="service.items" /> 
  </div>
</template>

<script>
import ServiceDetailsImage from '@/components/ServicesDetails/ServiceDetailsImage.vue'
import DetailsServices from '@/components/ServicesDetails/DetailsServices.vue'
import { services } from '@/api/services';


export default {
components: {
  ServiceDetailsImage,
  DetailsServices
},
data() {
    return {
      services,
      service: services[this.$route.params.id] 
    }
  }
}
</script>

<style scoped>
.background {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 0.5s ease-in-out;
}
</style>